import styled, { keyframes } from 'styled-components';

import {
  useHistory,
} from 'react-router-dom';

import BgImage from '../assets/intro.png';
import Container from '@mui/material/Container';

const bg = keyframes`
  0% {
    opacity: 1;
    filter: alpha(opacity=75);
  }
  100% {
    opacity: 0.25;
    filter: alpha(opacity=25);
  }
`

const BgBox = styled.div`
  margin: -48px 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  &: after {
    top:0;
    left:0;
    position:absolute;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    animation: ${bg} 2s 1s ease-in-out both;
    z-index:-1;
    content:"";
    width:100vw;
    height:100vh;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & img {
    width: 100%;
    height: auto;
  }
`

function Home({ participantInfo }: { participantInfo?: Record<string, any> }) {
  const history = useHistory();

  setTimeout(() => {
    if (!!participantInfo) {
      history.push('/my');
    }
  }, 750);

  return (
    <BgBox>
      <Container maxWidth="xs">
        <img src={BgImage} alt="intro" />
      </Container>
    </BgBox>
  )
}

export default Home;
