import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Home from './routes/Home';
import Invitation from './routes/Invitation';

import './translations';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const backendURL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8000/'

const theme = createTheme({
});

function RouterWrapped() {
  const query = useQuery();
  const participantHash = query.get('hash')
  const [participantInfo, setParticipantInfo] = React.useState(undefined);

  React.useEffect(() => {
    if (participantHash) {
      fetch(`${backendURL}s-oil-api/participants/${participantHash}`, {
        method: "GET",
        headers: {
          'content-type': 'application/json',
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json()
        })
        .then(response => {
          setParticipantInfo(response);
        })
        .catch(error => {
          console.error('Error:', error);
        })
    }
  }, [participantHash]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
        </div>
        <CssBaseline />
        <Container component="main" sx={{ minHeight: '100%', padding: '24px' }} maxWidth="md">
          <Switch>
            <Route exact path="/my">
              <Invitation participantInfo={participantInfo} />
            </Route>
            <Route path="/">
              <Home participantInfo={participantInfo} />
            </Route>
          </Switch>
        </Container>
        <div style={{ display: 'flex' }}>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#ffdd00', flexGrow: 1 }}>&nbsp;</div>
          <div style={{ backgroundColor: '#00854a', flexGrow: 1 }}>&nbsp;</div>
        </div>
      </div>
    </ThemeProvider>
  )
}

function App() {
  return (
    <BrowserRouter>
      <RouterWrapped />
    </BrowserRouter>
  );
}

export default App;
