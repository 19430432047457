import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import useStyles from '../common/styles';

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import Logo from '../assets/logo.png';
import InfoKor from '../assets/info_kor.jpg';
import InfoEng from '../assets/info_eng.jpg';

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import { backendURL } from '../App';
import { useState } from 'react';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

export default function Invitation({ participantInfo }: { participantInfo?: Record<string, any> }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isArrivalDisabled, setIsArrivalDisabled] = useState(true);
  const [isDepartureDisabled, setIsDepartureDisabled] = useState(true);
  const [isSpecialMealDisabled, setIsSpecialMealDisabled] = useState(true);
  const [isNationalityRok, setIsNationalityRok] = useState(true);
  const [isEventInfoOpen, setIsEventInfoOpen] = useState(false);
  const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);
  const [isThirdPartyAgreed, setIsThirdPartyAgreed] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const openAlertDialog = (title: string, message: string) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setIsAlertDialogOpen(true);
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      nameEng: "",
      gender: "",
      birthdate: "",
      nationality: "",
      company: "",
      position: "",
      address: "",
      officePhone: "",
      mobilePhone: "",
      flightDeparture: "",
      flightArrival: "",
      gloveSize: "",
      gloveLeftHanded: false,
      specialMeal: "",
    },
    onSubmit: (form) => {
      form.birthdate = dayjs(form.birthdate).format('YYYY-MM-DD');

      const requiredFields = [
        'name',
        'nameEng',
        'birthdate',
        'nationality',
        'company',
        'position',
        'address',
        'officePhone',
        'mobilePhone',
        'gloveSize',
        'specialMeal',
        'flightDeparture',
        'flightArrival',
      ];

      const objectToSubmit = { ...form };

      if (i18n.language === 'en') {
        requiredFields.splice(requiredFields.indexOf('nameEng'), 1);
      }

      if (isNationalityRok) {
        objectToSubmit.nationality = '대한민국';
        requiredFields.splice(requiredFields.indexOf('nameEng'), 1);
        requiredFields.splice(requiredFields.indexOf('nationality'), 1);
      }

      if (isDepartureDisabled) {
        objectToSubmit.flightDeparture = '김포발 → 제주행 10/30(수) 06:40';
        requiredFields.splice(requiredFields.indexOf('flightDeparture'), 1);
      }

      if (isArrivalDisabled) {
        objectToSubmit.flightArrival = '제주발 → 김포행 10/30(수) 21:10';
        requiredFields.splice(requiredFields.indexOf('flightArrival'), 1);
      }

      if (isSpecialMealDisabled) {
        objectToSubmit.specialMeal = '없음';
        requiredFields.splice(requiredFields.indexOf('specialMeal'), 1);
      }

      const missingFields = requiredFields.filter(field => !formik.values[field as keyof typeof formik.values]);

      if (missingFields.length) {
        openAlertDialog(t('error'), `${t('formError')} ${missingFields.map(field => t(field)).join(', ')}`);
        return;
      }

      if (!isPrivacyAgreed || !isThirdPartyAgreed) {
        openAlertDialog(t('error'), t('agreementError'));
        return;
      }

      if (participantInfo) {
        fetch(`${backendURL}s-oil-api/participants/${participantInfo.pHash}`, {
          method: "PUT",
          body: JSON.stringify(objectToSubmit),
          headers: {
            'content-type': 'application/json',
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(t('networkError'));
            }
            return response.json()
          })
          .then(response => {
            if (response.error) {
              openAlertDialog(t('error'), response.error);
            } else {
              openAlertDialog(t('notification'), t('submitSuccess'));
            }
          })
          .catch(error => {
            openAlertDialog(t('error'), t(error.message));
          })
      }
    },
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.paper}>
        <Container maxWidth="xs">
          <img src={Logo} alt="logo" style={{ width: '100%' }} />
        </Container>
        <Stack sx={{ margin: '8px auto' }} spacing={2} direction="row">
          <Button variant={i18n.language === 'ko' ? 'contained' : 'outlined'} startIcon={<span className="fi fi-kr"></span>} onClick={() => i18n.changeLanguage('ko')}>
            한국어
          </Button>
          <Button variant={i18n.language === 'en' ? 'contained' : 'outlined'} startIcon={<span className="fi fi-us"></span>} onClick={() => i18n.changeLanguage('en')}>
            English
          </Button>
        </Stack>
        <Typography variant="h6" gutterBottom textAlign="center" sx={{ whiteSpace: 'pre-wrap' }}>
          {t("formTitle")}
        </Typography>
        <Card className={classes.form}>
          <CardContent>
            <List>
              <InputLabel>{t('name')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined" name="name" onChange={formik.handleChange} value={formik.values.name} />
              </ListItem>
              <InputLabel>{t('gender')}</InputLabel>
              <ListItem>
                <RadioGroup row onChange={formik.handleChange} value={formik.values.gender} name="gender">
                  <FormControlLabel control={<Radio />} label={t('male')} value="M" />
                  <FormControlLabel control={<Radio />} label={t('female')} value="F" />
                </RadioGroup>
              </ListItem>
              <InputLabel>{t('birth')}</InputLabel>
              <ListItem>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                  <DatePicker sx={{ width: '100%' }} name="birthdate" value={formik.values.birthdate} onChange={(value) => formik.setFieldValue("birthdate", value, true)} />
                </LocalizationProvider>
              </ListItem>
              <InputLabel>{t('nationality')}</InputLabel>
              <ListItem>
                <RadioGroup>
                  <FormControlLabel control={<Radio />} label={t('rok')} onClick={() => setIsNationalityRok(true)}
                    value="none"
                    checked={isNationalityRok}
                  />
                  <FormControlLabel control={<Radio />} label={
                    <TextField fullWidth variant="outlined" placeholder={t('other')} size="small"
                      name="nationality" value={formik.values.nationality} onChange={formik.handleChange}
                      disabled={isNationalityRok}
                    />
                  }
                    value="nationality"
                    onClick={() => setIsNationalityRok(false)} />
                </RadioGroup>
              </ListItem>
              {
                i18n.language === 'ko' && !isNationalityRok && (<>
                  <InputLabel>영문 성함(Full Name)</InputLabel>
                  <ListItem>
                    <TextField fullWidth variant="outlined" name="nameEng" onChange={formik.handleChange} value={formik.values.nameEng} />
                  </ListItem>
                </>
                )
              }
              <InputLabel>{t('company')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="company" value={formik.values.company} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('position')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="position" value={formik.values.position} onChange={formik.handleChange}
                />
              </ListItem>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify', wordBreak: 'keep-all', color: 'rgba(0, 0, 0, 0.6)' }}>{t('deliveryHelp').replace(/^ +/gm, '')}</Typography>
              <InputLabel>{t('address')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined" multiline
                  name="address" value={formik.values.address} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('officePhone')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="officePhone" value={formik.values.officePhone} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('mobilePhone')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="mobilePhone" value={formik.values.mobilePhone} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('gloveSize')}</InputLabel>
              <ListItem>
                <FormGroup>
                  <Select size="small"
                    name="gloveSize" value={formik.values.gloveSize} onChange={formik.handleChange}
                  >
                    {Array.from({ length: 9 }, (_, i) => i + 18).map((size) => (
                      <MenuItem key={size} value={size}>{size}</MenuItem>
                    ))}
                  </Select>
                  <FormControlLabel control={<Checkbox
                    checked={formik.values.gloveLeftHanded}
                    onChange={formik.handleChange}
                    name="gloveLeftHanded"
                  />} label={t('leftHanded')} />
                </FormGroup>
              </ListItem>
              <InputLabel>{t('specialMeal')}</InputLabel>
              <ListItem>
                <RadioGroup>
                  <FormControlLabel control={<Radio />} label={t('none')} onClick={() => setIsSpecialMealDisabled(true)}
                    value="none"
                    checked={isSpecialMealDisabled}
                  />
                  <FormControlLabel control={<Radio />} label={
                    <TextField fullWidth variant="outlined" placeholder={t('specialMealDescription')} size="small"
                      name="specialMeal" value={formik.values.specialMeal} onChange={formik.handleChange}
                      disabled={isSpecialMealDisabled}
                    />
                  }
                    value="specialMeal"
                    onClick={() => setIsSpecialMealDisabled(false)} />
                </RadioGroup>
              </ListItem>
              <InputLabel>{t('flightDeparture')}</InputLabel>
              <ListItem>
                <RadioGroup>
                  <FormControlLabel control={<Radio />} label={<Typography variant="body2" sx={(theme) => ({ [theme.breakpoints.down('sm')]: { fontSize: i18n.language === 'en' ? '0.6rem' : undefined } })}>{t('departureDefault')}</Typography>} onClick={() => setIsDepartureDisabled(true)}
                    value="departureDefault"
                    checked={isDepartureDisabled}
                  />
                  <FormControlLabel control={<Radio />} label={
                    <TextField fullWidth variant="outlined" placeholder={t('other')} size="small"
                      name="flightDeparture" value={formik.values.flightDeparture} onChange={formik.handleChange}
                      disabled={isDepartureDisabled}
                    />
                  }
                    value="departureOther"
                    onClick={() => setIsDepartureDisabled(false)} />
                </RadioGroup>
              </ListItem>
              <InputLabel>{t('flightArrival')}</InputLabel>
              <ListItem>
                <RadioGroup>
                  <FormControlLabel
                    control={<Radio />} label={<Typography variant="body2" sx={(theme) => ({ [theme.breakpoints.down('sm')]: { fontSize: i18n.language === 'en' ? '0.6rem' : undefined } })}>{t('arrivalDefault')}</Typography>} onClick={() => setIsArrivalDisabled(true)}
                    value="arrivalDefault"
                    checked={isArrivalDisabled}
                  />
                  <FormControlLabel control={<Radio />} label={
                    <TextField fullWidth variant="outlined" placeholder={t('other')} size="small"
                      name="flightArrival" value={formik.values.flightArrival} onChange={formik.handleChange}
                      disabled={isArrivalDisabled}
                    />
                  }
                    value="arrivalOther"
                    onClick={() => setIsArrivalDisabled(false)} />
                </RadioGroup>
              </ListItem>
              {i18n.language === 'ko' && <Typography variant="body2" sx={{ textAlign: 'justify', wordBreak: 'keep-all', color: 'rgba(0, 0, 0, 0.6)' }}>
              ※ 김포 이외의 다른 공항 또는 상기와 다른 일정으로 출/도착하시는 경우, "기타" 항목에 체크하시고 해당 공항명과 일정을 기재해 주시면, 대회 사무국에서 별도로 연락드리겠습니다.
              </Typography>}
            </List>
            <Typography variant="subtitle1">
              {t("privacyTitle")}
            </Typography>
            <Typography variant="caption" gutterBottom sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>
              {t("privacyContent").replace(/^ +/gm, '')}
            </Typography>
            <RadioGroup row>
              <FormControlLabel control={<Radio size="small" />} label={t('yes')} value="Y" onClick={() => setIsPrivacyAgreed(true)} />
              <FormControlLabel control={<Radio size="small" />} label={t('no')} value="N" onClick={() => setIsPrivacyAgreed(false)} />
            </RadioGroup>
            <Typography variant="caption" gutterBottom sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>
              {t("privacyContentThirdParty").replace(/^ +/gm, '')}
            </Typography>
            <RadioGroup row>
              <FormControlLabel control={<Radio size="small" />} label={t('yes')} value="Y" onClick={() => setIsThirdPartyAgreed(true)} />
              <FormControlLabel control={<Radio size="small" />} label={t('no')} value="N" onClick={() => setIsThirdPartyAgreed(false)} />
            </RadioGroup>
            <Typography variant="body2" sx={{ textAlign: 'justify', wordBreak: 'keep-all', color: 'rgba(0, 0, 0, 0.6)' }}>
            <b>{t('submitHelp').replace(/^ +/gm, '')}</b><br />
            {t('additional').replace(/^ +/gm, '')}</Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
              <Button color="primary" type="submit" variant="contained">
                {t("submit")}
              </Button>
              <Button color="primary" variant="contained" onClick={() => setIsEventInfoOpen(true)}>
                {t("eventInfo")}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </div>
      <Dialog
        open={isEventInfoOpen}
        onClose={() => setIsEventInfoOpen(false)}
        fullScreen
        scroll="paper"
      >
        <DialogTitle>
          {t("eventInfo")}
        </DialogTitle>
        <DialogContent>
          <img src={i18n.language === 'ko' ? InfoKor : InfoEng} alt="event-info" style={{ width: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setIsEventInfoOpen(false)}>
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
      >
        <DialogTitle>
          {alertTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAlertDialogOpen(false)} color="primary">
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
