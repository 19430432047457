import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "formTitle": "S-OIL CHAMPIONSHIP 2024\nPRO-AM Participation Form",
          "privacyTitle": "2024 S-OIL CHAMPIONSHIP RSVP - Agreement for the Collection of Personal Information",
          "privacyContent": `
            The secreteriat seeks your permission for the collection and use of your personal information for the purpose of filling out the application form and managing the event.
            Your personal information will not be used for any purpose other than those stated above. The collected information will be securely destroyed after the event concludes.

            [Agreement for the Collection and Use of Personal Information]
            - Collected Information: Name, Date of Birth, Gender, Nationality, Address, Contact Information, Workplace
            - Purpose of Collection and Use: Management of Pro-Am participants and event operation
            - Retention and Usage Period: Stored for one month after the event, then destroyed

            You have the right to refuse consent; however, please note that refusal to consent may result in restrictions on your participation in the event. Do you agree to the collection and use of your personal information as described above?
            `
          ,
          'privacyContentThirdParty': `
            [Agreement for Provision of Personal Information to Third Parties]
            - Recipient: Brion Company
            - Purpose of the Recipient’s Use: Management of Pro-Am participants and event operation
            - Information Provided: Name, Date of Birth, Gender, Nationality, Address, Contact Information, Workplace
            - Retention and Usage Period by the Recipient: Stored for one month after the event, then destroyed

            You have the right to refuse consent; however, please note that refusal to consent may result in restrictions on your participation in the event. Do you agree to the collection and use of your personal information as described above?
          `,
          'yes': 'Yes',
          'no': 'No',
          'name': 'Name(Full Name)',
          'fullName': 'Full Name',
          'gender': 'Gender',
          'male': 'Male',
          'female': 'Female',
          'birth': 'Date of Birth',
          'nationality': 'Nationality',
          'rok': 'Republic of Korea',
          'company': 'Company',
          'position': 'Position',
          'address': 'Delivery Address',
          'officePhone': 'Delivery Contact Number',
          'mobilePhone': 'Mobile Phone Number',
          'flightDeparture': 'Airport of Departure (domestic flight)',
          'flightArrival': 'Airport of Arrival (domestic flight)',
          'departureDefault': 'Gimpo → Jeju : Depart at 06:40A.M. Oct.30',
          'arrivalDefault': 'Jeju → Gimpo : Depart at 21:10P.M. Oct.30',
          'other': 'Others',
          'submit': 'Submit',
          'gloveSize': 'Glove Size',
          'leftHanded': 'Check if you are left-handed',
          'specialMeal': 'Special Meal Request',
          'none': 'None',
          'specialMealDescription': 'Input request details',
          'submitHelp': `※ After entering all the information, please click the 'SUBMIT' below.`,
          'additional': `※ Inquiries : Secretariat (Tel : +82-2-6214-0104, Mobile : +82-10-3299-3441, Email : dongwoo0322@brion.co.kr)`,
          'eventInfo': 'EVENT INFO',
          'close': 'Close',
          'error': 'Error',
          'formError': 'Please check the input value following fields:',
          'submitSuccess': 'Your application has been successfully submitted.',
          'networkError': 'Network error. Please try again. If the problem persists, please contact the secretariat.',
          'agreementError': 'Please agree to the collection and use of personal information.',
          'notification': 'Notification',
          'deliveryHelp': '※ Please fill in your address where you can receive caddie bag flight cover prepared by S-OIL.',
        }
      },
      ko: {
        translation: {
          "formTitle": "S-OIL CHAMPIONSHIP 2024\n프로암 참가 신청서",
          "privacyTitle": "2024 S-OIL 챔피언십 – 개인정보 수집 동의",
          "privacyContent": `
            대회 사무국은 참가 신청서 작성 및 대회 운영을 위해 고객님의 개인정보를 수집하고자 합니다.
            
            수집된 개인정보는 프로암 참가 고객 관리 및 대회 운영 목적을 위해 사용됩니다. 동의하신 개인정보는 위에 명시된 수집 및 이용 목적 외로 사용되지 않습니다. 수집된 개인정보는 대회 종료 후 안전하게 파기됩니다.

            [개인정보 수집 이용 동의]
            - 개인정보 수집 항목: 이름, 생년월일, 성별, 국적, 주소, 연락처, 직장  
            - 개인정보 수집 및 이용목적: 참가 고객 관리 및 대회 운영
            - 보유 및 이용기간 : 행사 종료 후 1개월간 보관 후 파기

            귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 대회 참가 신청이 제한될 수 있음을 안내드립니다. 개인정보 수집 및 이용에 동의하십니까?
            `
          ,
          'privacyContentThirdParty': `
            [개인정보 제3자 제공 동의]
            - 제공받는자 : 브리온컴퍼니
            - 제공받는 자의 이용 목적 : 참가 고객 관리 및 대회 운영
            - 제공받는 항목 : 이름, 생년월일, 성별, 국적, 주소, 연락처, 직장
            - 제공받는 자의 보유 및 이용기간 : 행사 종료 후 1개월 간 보관 후 파기

            귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 대회 참가 신청이 제한될 수 있음을 안내드립니다. 개인정보 제 3자 제공에 동의하십니까?
          `,
          'yes': '예',
          'no': '아니요',
          'name': '성함',
          'gender': '성별',
          'male': '남',
          'female': '여',
          'birth': '생년월일',
          'nationality': '국적',
          'rok': '대한민국',
          'company': '회사',
          'position': '직위',
          'address': '택배 수령 주소',
          'officePhone': '택배 수령 연락처',
          'mobilePhone': '본인 휴대폰 번호',
          'flightDeparture': '출발 공항',
          'flightArrival': '도착 공항',
          'departureDefault': '김포발 → 제주행 10/30(수) 06:40',
          'arrivalDefault': '제주발 → 김포행 10/30(수) 21:10',
          'other': '기타',
          'submit': '정보 전송',
          'gloveSize': '장갑 사이즈',
          'leftHanded': '좌타자인 경우 체크',
          'specialMeal': '채식/알레르기 등 식사 관련 요청',
          'none': '없음',
          'specialMealDescription': '요청 사항 입력',
          'submitHelp': `※ 모든 정보를 입력하신 후 아래 '정보 전송'을 클릭해 주시기 바랍니다.`,
          'additional': `※ 문의 : 대회사무국(Tel : 02-6214-0104, Mobile : 010-3299-3441, Email : dongwoo0322@brion.co.kr)`,
          'eventInfo': '대회 안내',
          'close': '닫기',
          'error': '오류',
          'formError': '다음 항목의 정보를 확인해 주세요:',
          'submitSuccess': '참가 신청이 완료되었습니다.',
          'networkError': '네트워크 오류입니다. 다시 시도해 주세요. 문제가 지속되면 사무국으로 연락해 주세요.',
          'agreementError': '개인정보 수집 및 이용에 동의해 주세요.',
          'nameEng': '영문 성함(Full Name)',
          'notification': '알림',
          'deliveryHelp': '※ 캐디백 항공커버를 사전에 발송해 드릴 예정이오니 수령하실 주소를 반드시 기입하여 주시기 바랍니다.',
        }
      }
    },
    lng: "ko", // if you're using a language detector, do not define the lng option
    fallbackLng: "ko",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
